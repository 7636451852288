export const removeNewLineCharacters = (content) => {
  // removes \n and <br>
  if (content === undefined || content === null) {
    return '';
  }
  return content.replace(/\n+/g, '').replace(/<br\s*\/?>/g, '');
};

export const replaceNewLineCharactesWithBreaklines = (content) => {
  return content.replace(/\n/g, '<br>');
};

export const removeContainingTags = (content) => {
  // if it starts & ends with html-tags
  if (/^<.+>/.test(content) && /<\/.+>$/.test(content)) {
    return content.replace(/^<[^>]+>/, '').replace(/<\/.+\s?>$/, '');
  }
  return content;
};

export const emptyWYSIWYG = (content) => {
  if (content === null || content === undefined) {
    return true;
  }
  if (content.length === 0) {
    return true;
  }
  if (content.toString() === '<p><br></p>') {
    return true;
  }
  return false;
};
