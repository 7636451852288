import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Typography,
  TextField,
  Checkbox,
  Button,
  Box,
  FormHelperText,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import { Formik } from 'formik';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSnackbar } from 'notistack';
import config from '../../../config';
import LocationPicker from '../../Common/Formik/LocationPicker';
import AutocompleteChip from '../../Common/Formik/AutocompleteChip';
import StepTitle from '../../Common/PageLayout/StepTitle';
import StepWrapper from '../../Common/PageLayout/StepWrapper';
import WrappedDivider from '../../Common/WrappedDivider/WrappedDivider';
import ImageUpload from '../../Common/ImageUpload/ImageUpload';
import { actionTypes, Context } from '../../../Stores/EventInfoStore';
import { useApi, methods } from '../../../Hooks/useApi';
import { useFormData } from '../../../Hooks/useFormData';
import regexvalidation, { findUrlOnly } from '../../../Utils/regexvalidation';
import { object, string } from 'yup';
import {
  fieldContainsEmail,
  fieldContainsLink,
} from '../../../Consts/validationErrorMessages';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertFromHTML,
  ContentState,
  convertFromRaw,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { emptyWYSIWYG } from '../../../Utils/htmlTagUtils';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const getURL = (eventId) => `/event/${eventId}/course-details`;

const useStyles = makeStyles((theme) => ({
  buttonsGrid: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: '#FFFFFF',
    zIndex: 1,
    paddingTop: `${theme.spacing(2)} !important`,
    paddingBottom: `${theme.spacing(2)} !important`,
    paddingRight: `15% !important`,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
  },
  editMargin: {
    marginBottom: theme.spacing(7),
  },
  description: {
    border: `1px solid rgba(53, 62, 68, 0.25)`,
    borderRadius: 3,
    '&:hover': { border: `1px solid rgba(53, 62, 68, 0.5)` },
    '& div[role="textbox"]': {
      width: '100%',
      paddingLeft: 16.5,
      paddingRight: 16.5,
    },
  },
  supportText: {
    padding: '16px',
    marginBlockStart: '8px',
    fontSize: '12px',
    fontWeight: 400,
    color: 'rgba(53, 62, 68, 0.75)',
  },
}));

const validationSchema = object().shape({
  courseHighlights: string()
    .test('test-name', fieldContainsEmail, (value) => {
      return regexvalidation(value, 'email');
    })
    .test('test-name', fieldContainsLink, (value) => {
      return !findUrlOnly(value);
    }),
});

const CourseDetail = ({
  isManage = false,
  formRef,
  disableButton,
  formRefNotify,
}) => {
  const classes = useStyles();
  const [eventInfo, dispatch] = useContext(Context);
  const loaded = useRef(false);
  const { event } = eventInfo;
  const { eventId } = event;
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { executeFormDataCall } = useFormData();
  const [tags, setTags] = useState([]);
  const [image, setImage] = useState([eventInfo.courseDetails.courseMap]);
  const [submitButtonTextState, setSubmitButtonTextState] = useState(false);
  const [lastUpdateToEditor, setLastUpdateToEditor] = useState(new Date(0));

  const [editorState, setEditorState] = useState(() => {
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(eventInfo.courseDetails.courseHighlights ?? '')
      )
    );
  });

  const handleSubmit = async (values) => {
    setSubmitButtonTextState(true);
    let timediff = new Date().getTime() - lastUpdateToEditor.getTime();
    if (timediff < 2000) {
      await new Promise((r) => setTimeout(r, 1000 - timediff));
    }
    const {
      certification,
      courseProfile,
      courseSurface,
      courseType,
      environment,
      fieldSize,
      others,
    } = values;
    const allTags = certification.concat(
      courseProfile,
      courseSurface,
      courseType,
      environment,
      fieldSize,
      others
    );
    const tagIds = allTags
      .map((tag) => {
        return tags.filter((id) => id.label === tag).map((i) => i.id);
      })
      .flat();

    const data = {
      courseHighlights: stateToHTML(
        values.courseHighlights && !emptyWYSIWYG(values.courseHighlights)
          ? ContentState.createFromBlockArray(
              convertFromHTML(values.courseHighlights).contentBlocks,
              convertFromHTML(values.courseHighlights).entityMap
            )
          : ContentState.createFromText('')
      ),
      tags: tagIds,
      deleteCourseMap: !values.eventImages?.length ? true : false,
      startAddress: values.startAddress.place.description,
      startCoordinates: values.startAddress.place.latlng,
      finishAddress: values.finishAddress.place.description,
      finishCoordinates: values.finishAddress.place.latlng,
    };

    try {
      if (disableButton) {
        disableButton(true);
      }
      await executeFormDataCall(
        getURL(eventId),
        methods.post,
        values.eventImages,
        data
      );
      const storeTags = certification
        .concat(courseType, environment, fieldSize, others)
        .map((m) => {
          return tags.find((f) => f.label === m);
        })
        .map((m) => m.searchFacet);

      dispatch({
        type: actionTypes.courseDetails_set,
        payload: {
          courseProfile: values.courseProfile[0]?.toLowerCase(),
          courseSurface: values.courseSurface[0],
          tags: storeTags,
          courseHighlights: values.courseHighlights,
          courseMap: values.eventImages,
          startAddress: values.startAddress.place.description,
          startCoordinates: values.startAddress.place.latlng,
          finishAddress: values.finishAddress.place.description,
          finishCoordinates: values.finishAddress.place.latlng,
        },
      });
      setImage(values.eventImages);
      enqueueSnackbar('Course details saved successfully', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(JSON.parse(e.message)[0], { variant: 'error' });
    } finally {
      if (disableButton) {
        disableButton(true);
      }
    }
    setSubmitButtonTextState(false);
  };

  const mapCourseProfile = () =>
    tags?.find(
      (tag) => tag.searchFacet === eventInfo.courseDetails.courseProfile
    )?.label;

  const mapEnvironment = () =>
    eventInfo.courseDetails.tags
      .map((env) => {
        return tags.find(
          (tag) => tag.searchFacet === env && tag.type === 'environment'
        );
      })
      .filter(Boolean)
      .map((map) => map.label)
      .flat();

  const mapOtherSubType = (subtype) =>
    eventInfo.courseDetails.tags
      .map((m) => {
        return tags.find(
          (tag) => tag.searchFacet === m && tag.otherSubType === subtype
        );
      })
      .filter(Boolean)
      .map((map) => map.label)
      .flat();

  const mapOtherSubTypes = (subtype1, subtype2) =>
    eventInfo.courseDetails.tags
      .map((m) => {
        return tags.find(
          (tag) =>
            tag.searchFacet === m &&
            (tag.otherSubType === subtype1 || tag.otherSubType === subtype2)
        );
      })
      .filter(Boolean)
      .map((map) => map.label)
      .flat();

  const notify = async () => {
    if (!isManage) {
      await Promise.resolve();
      formRefNotify && formRefNotify();
    }
  };

  useEffect(() => {
    if (!isManage) notify(eventInfo.courseDetails);
    if (typeof image[0] === 'object') {
      setImage(image[0]);
    } else if (image[0] !== '' && image[0]?.path !== '' && image?.path !== '') {
      setImage([{ path: eventInfo.courseDetails.courseMap }]);
    } else {
      setImage([]);
    }
    if (disableButton) disableButton(false);
    const fetch = async () => {
      try {
        const data = await executeApiCall(
          `/event/course-details/tags`,
          methods.get
        );
        setTags(data);
      } catch (e) {}
    };
    fetch();
  }, []);

  function loadScript(src, position, id) {
    if (!position) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
  }

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_API_KEY}&libraries=places,maps`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  return (
    <>
      <StepTitle
        title="Course details (optional)"
        subtitle="Describe the kind of course and experience participants can expect when they take part in your event. This step can be skipped and added later."
      />
      <StepWrapper className={classes.editMargin}>
        <Grid container spacing={0} direction="row">
          <Grid xs={0} md={2}></Grid>
          <Grid xs={12} md={8}>
            <Formik
              validationSchema={validationSchema}
              enableReinitialize={true}
              initialValues={{
                courseProfile: eventInfo.courseDetails.courseProfile
                  ? [mapCourseProfile()]
                  : [],
                courseSurface: eventInfo.courseDetails.courseSurface
                  ? [eventInfo.courseDetails.courseSurface]
                  : [],
                environment: eventInfo.courseDetails.tags
                  ? mapEnvironment()
                  : [],
                fieldSize: eventInfo.courseDetails.tags
                  ? mapOtherSubType('field_size')
                  : [],
                courseType: eventInfo.courseDetails.tags
                  ? mapOtherSubType('course')
                  : [],
                certification: eventInfo.courseDetails.tags
                  ? mapOtherSubTypes('qualifier', 'certification')
                  : [],
                others: eventInfo.courseDetails.tags
                  ? mapOtherSubTypes('restriction', 'others')
                  : [],
                eventImages: image ? image : [],
                courseHighlights:
                  eventInfo.courseDetails.courseHighlights ?? '',

                startAddress: {
                  place: {
                    description: eventInfo.courseDetails.startAddress ?? '',
                    latlng: eventInfo.courseDetails.startCoordinates ?? [],
                  },
                },
                finishAddress: {
                  place: {
                    description: eventInfo.courseDetails.finishAddress ?? '',
                    latlng: eventInfo.courseDetails.finishCoordinates ?? [],
                  },
                },
              }}
              innerRef={formRef}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({
                values,
                errors,
                touched,
                setTouched,
                isSubmitting,
                setFieldValue,
                handleSubmit,
                handleBlur,
                dirty,
              }) => (
                <>
                  <form>
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <Typography gutterBottom>Tags</Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Autocomplete
                          multiple
                          label="Course profile"
                          name="Course profile"
                          id="courseProfile"
                          options={tags
                            .filter((o) => o.type === 'course_profile')
                            .map((m) => m.label)}
                          defaultValue={[]}
                          value={values.courseProfile}
                          onChange={(event, value) => {
                            value = value.slice(-1);
                            setFieldValue('courseProfile', value);
                            notify();
                          }}
                          renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option, index) => (
                              <AutocompleteChip
                                {...getTagProps({ index })}
                                label={tagValue[0]}
                              />
                            ));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Course profile"
                              name="courseProfile"
                            />
                          )}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <Autocomplete
                          multiple
                          label="Course surface"
                          name="Course surface"
                          id="courseSurface"
                          options={tags
                            .filter((o) => o.type === 'course_surface')
                            .map((m) => m.label)}
                          defaultValue={[]}
                          value={values.courseSurface}
                          onChange={(event, value) => {
                            value = value.slice(-1);
                            setFieldValue('courseSurface', value);
                            notify();
                          }}
                          renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option, index) => (
                              <AutocompleteChip
                                {...getTagProps({ index })}
                                label={tagValue[0]}
                              />
                            ));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Course surface"
                              name="courseSurface"
                            />
                          )}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <Autocomplete
                          multiple
                          label="Environment"
                          disableCloseOnSelect
                          name="Environment"
                          id="environment"
                          value={values.environment}
                          options={tags
                            .filter((o) => o.type === 'environment')
                            .map((m) => m.label)}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          onChange={(event, value) => {
                            setFieldValue('environment', value);
                            notify();
                          }}
                          renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option, index) => (
                              <AutocompleteChip
                                {...getTagProps({ index })}
                                label={option}
                              />
                            ));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Environment"
                              name="environment"
                            />
                          )}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <Autocomplete
                          multiple
                          label="Field size"
                          name="Field size"
                          id="fieldSize"
                          options={tags
                            .filter((o) => o.otherSubType === 'field_size')
                            .map((m) => m.label)}
                          defaultValue={[]}
                          value={values.fieldSize}
                          onChange={(event, value) => {
                            value = value.slice(-1);
                            setFieldValue('fieldSize', value);
                            notify();
                          }}
                          renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option, index) => (
                              <AutocompleteChip
                                {...getTagProps({ index })}
                                label={tagValue[0]}
                              />
                            ));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Field size"
                              name="fieldSize"
                            />
                          )}
                        />
                      </Grid>

                      <Grid xs={12}>
                        <Autocomplete
                          multiple
                          label="Course type"
                          name="Course type"
                          id="courseType"
                          options={tags
                            .filter((o) => o.otherSubType === 'course')
                            .map((m) => m.label)}
                          defaultValue={[]}
                          value={values.courseType}
                          onChange={(event, value) => {
                            value = value.slice(-1);
                            setFieldValue('courseType', value);
                            notify();
                          }}
                          renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option, index) => (
                              <AutocompleteChip
                                {...getTagProps({ index })}
                                label={tagValue[0]}
                              />
                            ));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Course type"
                              name="courseType"
                            />
                          )}
                        />
                      </Grid>

                      <Grid xs={12}>
                        <Autocomplete
                          multiple
                          label="Certification/Qualifier"
                          disableCloseOnSelect
                          name="Certification/Qualifier"
                          id="certification"
                          options={tags
                            .filter(
                              (o) =>
                                o.otherSubType === 'qualifier' ||
                                o.otherSubType === 'certification'
                            )
                            .map((m) => m.label)}
                          value={values.certification}
                          defaultValue={[]}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          onChange={(event, value) => {
                            setFieldValue('certification', value);
                            notify();
                          }}
                          renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option, index) => (
                              <AutocompleteChip
                                {...getTagProps({ index })}
                                label={option}
                              />
                            ));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Certification/Qualifier"
                              name="certification"
                            />
                          )}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <Autocomplete
                          multiple
                          label="Others"
                          disableCloseOnSelect
                          name="Others"
                          id="others"
                          options={tags
                            .filter(
                              (o) =>
                                o.otherSubType === 'others' ||
                                o.otherSubType === 'restriction'
                            )
                            .map((m) => m.label)}
                          defaultValue={[]}
                          value={values.others}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                          onChange={(event, value) => {
                            setFieldValue('others', value);
                            notify();
                          }}
                          renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option, index) => (
                              <AutocompleteChip
                                {...getTagProps({ index })}
                                label={option}
                              />
                            ));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Others"
                              name="others"
                            />
                          )}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <WrappedDivider />
                      </Grid>
                      <Grid xs={12}>
                        <Typography gutterBottom>Course map</Typography>
                      </Grid>
                      <Grid xs={12}>
                        <ImageUpload
                          maxFiles={1}
                          allowVerticalImages={true}
                          onChange={(file) => {
                            setFieldValue('eventImages', file);
                            notify();
                          }}
                          onReorder={(file) => {
                            setFieldValue('eventImages', file);
                          }}
                          files={values.eventImages}
                          name="eventImages"
                          helperText="The maximum size of course map image is 5MB.  Please make sure you own the rights to the uploaded image and/or provide attribution in the image."
                          errorMessage={
                            touched.eventImages &&
                            Boolean(errors.eventImages) &&
                            (errors.eventImages ||
                              `Upload up to one good quality image. The image must be at least 2160x1080px
                      (2:1) and maximum 5MB. For best results avoid an image that contains text
                      or logos. The image will be displayed on your event page.`)
                          }
                        />
                      </Grid>
                      <Grid xs={12}>
                        <WrappedDivider paddingBottom={3} />
                      </Grid>
                      <div id="map"></div>
                      <Grid xs={12}>
                        <LocationPicker
                          isVirtualEvent={
                            eventInfo.basicInfo.eventType === 'virtual'
                          }
                          location={values.startAddress.place}
                          label="Start address"
                          name="startAddress"
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          setTouched={setTouched}
                          errors={errors}
                          touched={touched}
                          required={false}
                          returnLatLng={true}
                          swapLatLng={true}
                          notify={notify}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <LocationPicker
                          isVirtualEvent={
                            eventInfo.basicInfo.eventType === 'virtual'
                          }
                          location={values.finishAddress.place}
                          label="Finish address"
                          name="finishAddress"
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          setTouched={setTouched}
                          touched={touched}
                          required={false}
                          swapLatLng={true}
                          returnLatLng={true}
                          notify={notify}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <Box pt={3}>
                          <h2>Course highlights</h2>
                          <div
                            className={classes.description}
                            style={{
                              borderColor:
                                touched.courseHighlights &&
                                Boolean(errors.courseHighlights)
                                  ? 'red'
                                  : null,
                            }}
                          >
                            <Editor
                              toolbar={{
                                options: ['inline', 'list'],
                                inline: { options: ['bold', 'italic'] },
                                list: { options: ['unordered'] },
                              }}
                              label="courseHighlights"
                              defaultEditorState={editorState}
                              onChange={(e) => {
                                setEditorState(
                                  EditorState.createWithContent(
                                    convertFromRaw(e)
                                  )
                                );
                                setTouched({
                                  ...touched,
                                  courseHighlights: true,
                                });
                                setFieldValue(
                                  'courseHighlights',
                                  stateToHTML(editorState.getCurrentContent())
                                );
                                setLastUpdateToEditor(new Date());
                              }}
                              onContentStateChange={(e) => {
                                setFieldValue(
                                  'courseHighlights',
                                  stateToHTML(convertFromRaw(e))
                                );
                              }}
                              name="courseHighlights"
                              helperText="Provide details of the course(s) and any highlights such as sights along the route."
                            />
                          </div>
                          <FormHelperText
                            className={`${classes.helperText} ${classes.supportText}`}
                            error={
                              touched.courseHighlights &&
                              Boolean(errors.courseHighlights)
                            }
                          >
                            {touched.courseHighlights &&
                            Boolean(errors.courseHighlights)
                              ? errors.courseHighlights
                              : `Provide details of the course(s) and any highlights such as sights along the route.`}
                          </FormHelperText>
                        </Box>
                      </Grid>
                      {isManage && (
                        <Grid
                          container
                          className={classes.buttonsGrid}
                          justifyContent="flex-end"
                          spacing={0}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={async (e) => {
                              while (
                                new Date().getTime() -
                                  lastUpdateToEditor.getTime() <
                                1000
                              ) {}
                              await handleSubmit(e);
                            }}
                            disabled={!dirty || isSubmitting}
                          >
                            {submitButtonTextState
                              ? 'Saving course details…'
                              : 'Save course details'}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </form>
                </>
              )}
            </Formik>
          </Grid>
          <Grid xs={0} md={2}></Grid>
        </Grid>
      </StepWrapper>
    </>
  );
};

export default CourseDetail;
