import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertFromRaw,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useField, useFormikContext } from 'formik';
import { stateToHTML } from 'draft-js-export-html';
import { emptyWYSIWYG } from '../../../Utils/htmlTagUtils';

const useStyles = makeStyles(() => ({
  container: {
    display: `flex`,
    flexDirection: `column`,
    width: `100%`,
    padding: 12,
  },
  answer: {
    border: `1px solid rgba(53, 62, 68, 0.25)`,
    borderRadius: 3,
    '&:hover': { border: `1px solid rgba(53, 62, 68, 0.5)` },
    '& div[role="textbox"]': {
      width: '100%',
      paddingLeft: 16.5,
      paddingRight: 16.5,
    },
  },
  error: {
    padding: '16px',
    marginBlockStart: '8px',
    fontSize: '12px',
    fontWeight: 400,
    color: `red`,
    '&:empty': {
      display: 'none !important',
      margin: 0,
      padding: 0,
    },
  },
}));

const FAQ = ({ title, editHandler, defaultValue }) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(title);

  const [editorState, setEditorState] = useState(() => {
    if (emptyWYSIWYG(defaultValue?.answer)) {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(''))
      );
    }
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(defaultValue?.answer ?? '')
      )
    );
  });

  useEffect(() => {
    if (!emptyWYSIWYG(stateToHTML(editorState.getCurrentContent()))) {
      setFieldValue(title, stateToHTML(editorState.getCurrentContent()));
    }
  }, []);

  return (
    <div className={classes.container}>
      <h3>{title}</h3>
      <div
        className={classes.answer}
        style={{ borderColor: meta.error ? 'red' : null }}
      >
        <Editor
          toolbar={{
            options: ['inline', 'list'],
            inline: { options: ['bold', 'italic'] },
            list: { options: ['unordered'] },
          }}
          defaultEditorState={editorState}
          onContentStateChange={(e) => {
            setEditorState(EditorState.createWithContent(convertFromRaw(e)));
            setFieldValue(title, stateToHTML(editorState.getCurrentContent()));
            editHandler(stateToHTML(editorState.getCurrentContent()));
          }}
        />
      </div>
      <p className={classes.error}>{meta.error ?? ''}</p>
    </div>
  );
};

export default FAQ;
