import React from 'react';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import FormikTextField from '../Common/Formik/FormikTextField';
import { useApi, methods } from '../../Hooks/useApi';

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').nullable(),
});

const ExportModal = ({ reportType, open, onClose, exportURL, title }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { executeApiCall } = useApi();

  const confirm = async (values) => {
    const payload = { reportType: reportType, email: values.email };
    try {
      await executeApiCall(exportURL, methods.post, payload);
      enqueueSnackbar(
        'Your export has been started and will be ready in a few minutes.',
        { variant: 'success' }
      );
      onClose();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          email: '',
        }}
        onSubmit={confirm}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                It may take a few minutes to create the export so we will email
                it to you when it's ready. You can leave the input blank if you
                would like the export to be sent to your inbox, or add another
                email address for the file to be sent to.
              </DialogContentText>
              <FormikTextField label="Email address" name="email" fullWidth />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                disabled={isSubmitting}
                onClick={handleSubmit}
                color="primary"
              >
                Get Export
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default ExportModal;
