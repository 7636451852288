import React, { useContext, useState } from 'react';
import {
  MenuItem,
  ListItemIcon,
  FormControlLabel,
  Typography,
  Checkbox,
  Box,
  IconButton,
  LinearProgress,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import {
  Subject,
  ArrowDropDownCircle,
  CloudDownload,
  CheckBox,
  Delete,
} from '@mui/icons-material';
import { FieldArray, getIn } from 'formik';
import {
  CustomQuestionOptions,
  TextValidationOptions,
} from './CustomQuestionOptions';
import { useSnackbar } from 'notistack';
import FileUploadButton from '../../../Common/FileUploadButton/FileUploadButton';
import FormikTextField from '../../../Common/Formik/FormikTextField';
import * as inputType from '../../../../Consts/inputType';
import { text } from '../../../../Consts/customQuestionInputValidationType';
import { useFormData } from '../../../../Hooks/useFormData';
import { methods } from '../../../../Hooks/useApi';
import { Context } from '../../../../Stores/EventInfoStore';

const getCustomQuestionFileUrl = (eventId, editionId) =>
  `/ro/events/${eventId}/races/${editionId}/products/custominfofile`;

const useStyles = makeStyles((theme) => ({
  select: {
    '& .MuiOutlinedInput-input': {
      padding: '14px 14.5px',
    },
  },
  fileError: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  fileName: { overflow: 'hidden' },
  paddingEight: {
    padding: theme.spacing(1),
  },
}));

const newSubQuestion = {
  label: '',
  type: '',
  validation: text,
  values: ['', ''],
  file: null,
};

const CustomQuestionForm = ({ values, ...props }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.paddingEight}>
      <CustomQuestion namePrefix="" values={values} {...props} />
      {(values.type === inputType.dropdown ||
        values.type === inputType.multi_choice) && (
        <FieldArray
          name="subQuestions"
          render={(arrayHelpers) => (
            <>
              <Grid xs={7}>
                <SubQuestionCheckbox
                  checked={Boolean(values.subQuestions.length)}
                  uncheckAction={() => arrayHelpers.remove(0)}
                  checkAction={() => arrayHelpers.push(newSubQuestion)}
                />
              </Grid>
              {values.subQuestions.map((sub, i) => (
                <React.Fragment key={i}>
                  <SubQuestion
                    subQuestion={sub}
                    namePrefix={`subQuestions.${i}.`}
                    options={values.values}
                    {...props}
                  />
                  <Grid xs={7}>
                    <SubQuestionCheckbox
                      checked={Boolean(values.subQuestions[i + 1])}
                      uncheckAction={() => arrayHelpers.remove(i + 1)}
                      checkAction={() => arrayHelpers.push(newSubQuestion)}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </>
          )}
        />
      )}
    </Grid>
  );
};

const CustomQuestion = ({
  namePrefix,
  values,
  touched,
  errors,
  setFieldValue,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { executeFormDataCall, formLoading } = useFormData();
  const [eventInfo, _] = useContext(Context);
  const { eventId, editionId } = eventInfo.event;
  const [tempFile, setTempFile] = useState({});

  const onFileChange = async (file) => {
    try {
      setTempFile(file);
      const response = await executeFormDataCall(
        getCustomQuestionFileUrl(eventId, editionId),
        methods.post,
        [file]
      );
      if (!formLoading) {
        setTempFile({});
      }
      const renamed = { name: response, path: response, size: file.size };
      setFieldValue(`${namePrefix}file`, renamed);
    } catch {
      enqueueSnackbar(
        'File upload failed. Please try again or choose another file',
        { variant: 'error' }
      );
    }
  };

  const onFileDelete = () => {
    const filesToRemove = values.filesToRemove;
    setFieldValue(`${namePrefix}filesToRemove`, [
      ...filesToRemove,
      values.file.name,
    ]);
    setFieldValue(`${namePrefix}file`, null);
  };

  return (
    <Grid container spacing={2} className={classes.paddingEight}>
      <Grid xs={12}>
        <FormikTextField
          variant="outlined"
          label="Custom question"
          placeholder="Enter custom question"
          required
          fullWidth
          autoFocus={!Boolean(namePrefix)}
          name={`${namePrefix}label`}
        />
      </Grid>
      <Grid xs={7}>
        <FormikTextField
          name={`${namePrefix}type`}
          label="Answer type"
          fullWidth
          className={classes.select}
          select
        >
          <MenuItem value={inputType.text}>
            <ListItemIcon>
              <Subject />
            </ListItemIcon>
            Text field
          </MenuItem>
          <MenuItem value={inputType.dropdown}>
            <ListItemIcon>
              <ArrowDropDownCircle />
            </ListItemIcon>
            Dropdown
          </MenuItem>
          <MenuItem value={inputType.multi_choice}>
            <ListItemIcon>
              <CheckBox />
            </ListItemIcon>
            Multi-select
          </MenuItem>
          <MenuItem value={inputType.file_download}>
            <ListItemIcon>
              <CloudDownload />
            </ListItemIcon>
            File download
          </MenuItem>
        </FormikTextField>
      </Grid>
      <Grid xs={7}>
        {values.type === inputType.text ? (
          <TextValidationOptions
            namePrefix={namePrefix}
            value={values.validation}
          />
        ) : values.type === inputType.dropdown ||
          values.type === inputType.multi_choice ? (
          <CustomQuestionOptions
            namePrefix={namePrefix}
            options={values.values}
            touched={touched.values}
            error={getIn(errors, `${namePrefix}values`)}
          />
        ) : values.type === inputType.file_download ? (
          !Boolean(values.file) ? (
            <>
              {!formLoading && (
                <>
                  <FileUploadButton
                    file={values.file}
                    setFile={onFileChange}
                    size={10485760}
                  />
                  <Box className={classes.fileError}>
                    <Typography variant="caption">
                      {touched.file && errors.file}
                    </Typography>
                  </Box>
                </>
              )}
              {formLoading && (
                <Grid container spacing={0} alignItems="center" direction="row">
                  <Grid xs={8} className={classes.fileName}>
                    <Typography variant="subtitle2" noWrap>
                      {tempFile.name}
                    </Typography>
                    <LinearProgress />
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid container spacing={0} alignItems="center" direction="row">
                <Grid xs={8} className={classes.fileName}>
                  <Typography variant="subtitle2" noWrap>
                    {values.file.name}
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton onClick={onFileDelete} size="large">
                    <Delete fontSize="inherit" />
                  </IconButton>
                </Grid>
              </Grid>
            </>
          )
        ) : null}
      </Grid>
    </Grid>
  );
};

const SubQuestion = ({ options, subQuestion, namePrefix, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2} className={classes.paddingEight}>
        <Grid xs={7}>
          <FormikTextField
            name={`${namePrefix}previousAnswer`}
            label="If the participant chooses:"
            fullWidth
            select
            autoFocus
          >
            {options.map((o, i) => (
              <MenuItem key={`${o}-${i}`} value={o}>
                {o}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
      </Grid>
      <CustomQuestion values={subQuestion} namePrefix={namePrefix} {...props} />
    </>
  );
};

const SubQuestionCheckbox = ({ checked, checkAction, uncheckAction }) => {
  const [isChecked, toggleChecked] = useState(checked);
  const checkboxClick = () => {
    toggleChecked(!isChecked);
    if (isChecked) uncheckAction();
    else checkAction();
  };

  return (
    <FormControlLabel
      name="subquestion"
      control={
        <Checkbox
          checked={isChecked}
          onChange={() => checkboxClick()}
          color="primary"
        />
      }
      label={
        isChecked ? (
          <Typography>Remove the following sub-question</Typography>
        ) : (
          <Typography>Add conditional sub-question</Typography>
        )
      }
    />
  );
};

export default CustomQuestionForm;
