import React, { useContext, useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { mutate } from 'swr';
import {
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Button,
  InputAdornment,
  FormHelperText,
  Tooltip,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EventDatesModal from './EventDatesModal';
import PriceStepsModal from './PriceStepsModal';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import isEqual from 'date-fns/isEqual';
import isWithinInterval from 'date-fns/isWithinInterval';
import ImageUpload from '../../Common/ImageUpload/ImageUpload';
import LocationPicker from '../../Common/Formik/LocationPicker';
import LocationMap from './LocationMap';
import CurrencyPicker from './CurrencyPicker';
import EventTitle from './EventTitle';
import StepWrapper from '../../Common/PageLayout/StepWrapper';
import BasicInfoValidation from './BasicInfoValidation';
import FormikTextField from '../../Common/Formik/FormikTextField';
import StepTitle from '../../Common/PageLayout/StepTitle';
import FocusFormikError from '../../Common/Formik/FocusFormikError';
import { actionTypes, Context } from '../../../Stores/EventInfoStore';
import { useFormData } from '../../../Hooks/useFormData';
import { methods, useApi } from '../../../Hooks/useApi';
import config from '../../../config';
import ConfirmationModal from '../../Common/ConfirmationModal/ConfirmationModal';
import ReactivationModal from '../ReactivateEvent/ReactivationModal';
import GenericModal from '../../Common/GenericModal/GenericModal';
import { publish } from '../../../Consts/eventStatus';
import { onKeyDown } from '../../../Utils/dateUtils';
import { formatApiDateTime } from '../../../Format';
import {
  maximumNumberOfImages,
  maxImagesReached,
} from '../../../Consts/imageCount';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  EditorState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';

const updateBasicInfoUrl = (eventId, editionId) =>
  `/event/${eventId}/${editionId}/basicinfo`;

const reactivateBasicInfoUrl = (eventId) =>
  `/event/${eventId}/reactivate-basic-info`;

const uploadImageUrl = (eventId) => `/event/${eventId}/basicinfo/images`;

const imageHelperText =
  'Upload up to 6 good quality images. Images must be at least 2160x1080px (2:1) and maximum 5MB. For best results avoid images that contain text or logos. These images will be displayed on your event page.';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const getSaleEndDefault = (type, eventDate) => {
  let d = new Date(eventDate);
  return type === 'virtual' ? d : new Date(d.setDate(d.getDate() - 1));
};

const getLocalStartDate = (ticket, newStartDate, newEndDate) => {
  return ticket.localStartDate === null
    ? null
    : isWithinInterval(new Date(ticket.localStartDate), {
        start: new Date(newStartDate),
        end: new Date(newEndDate),
      })
    ? new Date(ticket.localStartDate)
    : new Date(newStartDate);
};

const useStyles = makeStyles((theme) => ({
  grid: { margin: 'auto' },
  topHeading: {
    flexGrow: 1,
  },
  headerBox: {
    flex: 1,
    display: 'flex',
    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  cardContent: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  helperText: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
  },
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  buttonsGrid: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: '#FFFFFF',
    zIndex: 1,
    paddingTop: `${theme.spacing(2)} !important`,
    paddingBottom: `${theme.spacing(2)} !important`,
    paddingRight: `15% !important`,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
  },
  editMargin: {
    marginBottom: theme.spacing(7),
  },
  description: {
    border: `1px solid rgba(53, 62, 68, 0.25)`,
    borderRadius: 3,
    '&:hover': { border: `1px solid rgba(53, 62, 68, 0.5)` },
    '& div[role="textbox"]': {
      width: '100%',
      paddingLeft: 16.5,
      paddingRight: 16.5,
    },
  },
}));

const dayBefore = (date) => {
  if (date === null) return null;
  if (typeof date === 'string') date = new Date(date);
  let dayBefore = new Date(date);
  return dayBefore.setDate(date.getDate() - 1);
};

const BasicInfo = (props) => {
  const classes = useStyles();
  const loaded = useRef(false);
  const [reactivationPrompt, setReactivationPrompt] = useState(false);
  const [reactivateInfoModal, toggleReactivateInfoModal] = useState(false);
  const [cantChangeDateModal, toggleCantChangeDateModal] = useState(false);
  const [eventDatesModal, toggleEventDatesModal] = useState(false);
  const [priceStepsModal, togglePriceStepsModal] = useState(false);
  const [priceStepsTickets, setPriceStepsTickets] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { executeFormDataCall, formError } = useFormData();
  const { executeApiCall, apiError } = useApi();
  const [eventInfo, dispatch] = useContext(Context);
  const navigate = useNavigate();
  const { basicInfo, event, allEditions, eventPageStatus, canChangeCurrency } =
    eventInfo;
  const [lastUpdateToEditor, setLastUpdateToEditor] = useState(new Date(0));
  const [now, setNow] = useState(new Date());

  const isPast = new Date(basicInfo.eventEndDate) <= new Date();
  const disableDates = eventPageStatus === publish && isPast;
  const futureEvent = allEditions?.find((x) => new Date(x.date) > new Date());
  const checkReactivationPrompt = () => {
    if (disableDates && futureEvent && !futureEvent.inProgress)
      toggleCantChangeDateModal(true);
    else if (disableDates) setReactivationPrompt(true);
  };

  useEffect(() => {
    //In case the user goes back from a previous
    //step before finishing
    if (props.disableButton) props.disableButton(false);
  }, []);

  useEffect(() => {
    setInterval(() => {
      setNow(new Date());
    }, 100);
  }, []);

  const updateUrl = (eventId, editionId) => navigate(`${eventId}/${editionId}`);
  const ticketUpdateUrl = (eventId, editionId) =>
    `/ticket/${eventId}/${editionId}/ticket/bulk-date-update`;

  const saveBasicInfo = async (values) => {
    const { eventImages, ...otherProperties } = values;
    try {
      const res = await executeFormDataCall(
        '/event/basicinfo',
        methods.post,
        eventImages,
        otherProperties
      );
      dispatch({ type: actionTypes.basicInfo_set, payload: values });
      dispatch({ type: actionTypes.event_set, payload: res });
      updateUrl(res.eventId, res.editionId);
      props.onSaveSuccess(res.eventId, res.editionId);

      if (res.imageErrors?.length) {
        res.imageErrors.forEach((x) =>
          enqueueSnackbar(x, { variant: 'error' })
        );
        props.stepUpdate(0);
      }
    } catch {}
  };

  const updateBasicInfo = async (values) => {
    values.imageOrder = values.eventImages.map((i) => i.id);
    try {
      await executeApiCall(
        updateBasicInfoUrl(event.eventId, event.editionId),
        methods.put,
        values
      ).then(async () => {
        if (new Date(values.eventStartDate) <= new Date(values.eventEndDate))
          await handleEventSalesDateModals(values);
      });
      enqueueSnackbar('Basic information saved successfully', {
        variant: 'success',
      });
      mutate(`/event/${event.eventId}/edition/${event.editionId}`);
      if (props.onSaveSuccess) props.onSaveSuccess();
    } catch {}
  };

  const reactivateBasicInfo = async (values) => {
    values.imageOrder = values.eventImages.map((i) => i.id);
    try {
      const editionId = await executeApiCall(
        reactivateBasicInfoUrl(event.eventId),
        methods.post,
        values,
        null,
        true
      );
      enqueueSnackbar('New edition successfully created', {
        variant: 'success',
      });
      navigate(`${editionId}`);
      props.onSaveSuccess();
    } catch {}
  };

  useEffect(() => {
    const error = formError || apiError;
    if (error) enqueueSnackbar(error, { variant: 'error' });
  }, [formError, apiError, enqueueSnackbar]);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_API_KEY}&libraries=places,maps`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const [ticketModal, setTicketModal] = useState(eventInfo.tickets);

  const editorState = EditorState.createWithContent(
    ContentState.createFromBlockArray(
      convertFromHTML(basicInfo.eventDescription ?? '')
    )
  );

  const updateTickets = async (eventId, editionId, values) => {
    try {
      const filtered = values.map((ticket) => {
        return {
          ticketId: ticket.id,
          localStartDate: formatApiDateTime(new Date(ticket.localStartDate)),
          localStartTime: ticket.localStartTime,
        };
      });
      const payload = { ticketDateTimeAdjustments: filtered };
      await executeApiCall(
        ticketUpdateUrl(eventId, editionId),
        methods.post,
        payload
      );
      dispatch({
        type: actionTypes.ticket_bulk_update,
        payload: filtered,
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const handleEventSalesDateModals = async (values) => {
    if (
      (!isEqual(
        new Date(eventInfo.basicInfo.eventStartDate),
        new Date(values.eventStartDate)
      ) ||
        !isEqual(
          new Date(eventInfo.basicInfo.eventEndDate),
          new Date(values.eventEndDate)
        )) &&
      eventInfo.tickets.length > 0
    ) {
      const updatedTickets = [];
      eventInfo.tickets.forEach((t) => {
        updatedTickets.push({
          ...t,
          localStartDate: getLocalStartDate(
            t,
            values.eventStartDate,
            values.eventEndDate
          ),
        });
      });
      setTicketModal(updatedTickets);
      toggleEventDatesModal(true);
      await updateTickets(event.eventId, event.editionId, updatedTickets);
    } else if (
      (!isEqual(
        new Date(eventInfo.basicInfo.eventSaleStartDate),
        new Date(values.eventSaleStartDate)
      ) ||
        !isEqual(
          new Date(eventInfo.basicInfo.eventSaleEndDate),
          new Date(values.eventSaleEndDate)
        )) &&
      eventInfo.tickets.length > 0
    ) {
      const ps = [];
      eventInfo.tickets.forEach((ticket) => {
        if (ticket.priceSteps.length > 0) ps.push(ticket);
      });
      setPriceStepsTickets(ps);
      if (ps.length > 0) togglePriceStepsModal(true);
    }
  };

  return (
    <>
      <StepTitle
        title="Basic information"
        subtitle="Start creating your event page by adding the basic information about your event. 
    Most information will be shown to athletes on your event page"
      />
      <StepWrapper className={classes.editMargin}>
        <BasicInfoValidation
          lastUpdateToEditor={lastUpdateToEditor}
          saveBasicInfo={
            props.isUpdate
              ? updateBasicInfo
              : props.initReactivating
              ? reactivateBasicInfo
              : saveBasicInfo
          }
          basicInfo={basicInfo}
          {...props}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setTouched,
            values,
            touched,
            errors,
            isSubmitting,
            dirty,
          }) => (
            <Grid container justifyContent="center" spacing={0}>
              <Grid xs={12} sm={8} container spacing={3}>
                {props.isUpdate ? (
                  <FormikTextField
                    variant="outlined"
                    fullWidth
                    label="Event title"
                    name="eventTitle"
                    placeholder="Name of your event"
                    required
                  />
                ) : (
                  <EventTitle
                    values={values}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    handleBlur={handleBlur}
                  />
                )}

                <Divider className={classes.divider} />

                <Grid xs={12}>
                  <Tooltip
                    title="Event type can not be changed once the event has been created"
                    placement="top"
                    disableHoverListener={
                      !props.isUpdate || props.initReactivating
                    }
                  >
                    <FormControl
                      disabled={props.isUpdate || props.initReactivating}
                      component="fieldset"
                    >
                      <FormLabel component="legend">Event type</FormLabel>
                      <RadioGroup
                        row
                        aria-label="event-type"
                        name="eventType"
                        value={values.eventType}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          color="default"
                          value="regular"
                          control={<Radio />}
                          label="Regular event"
                        />
                        <FormControlLabel
                          color="default"
                          value="virtual"
                          control={<Radio />}
                          label="Virtual event"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid xs={12}>
                  <LocationPicker
                    isVirtualEvent={values.eventType === 'virtual'}
                    location={values.eventLocation.place}
                    name="eventLocation"
                    label="Location"
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    setTouched={setTouched}
                    touched={touched}
                    required={true}
                    returnLatLng={false}
                  />
                  <FormHelperText
                    className={classes.helperText}
                    color="initial"
                  >
                    The name of the country and city will be displayed as the
                    basic information on the event page.
                  </FormHelperText>
                  {touched.eventLocation &&
                    Boolean(values.eventLocation.place) && (
                      <LocationMap
                        place={values.eventLocation.place}
                        setFieldValue={setFieldValue}
                      />
                    )}
                </Grid>
                <Divider className={classes.divider} />
                <Grid container direction="row" xs={12} spacing={2}>
                  <Grid xs={12}>
                    <Typography gutterBottom>Dates</Typography>
                  </Grid>
                  <Grid xs={6}>
                    <span onClick={() => checkReactivationPrompt()}>
                      <DatePicker
                        disabled={disableDates}
                        disablePast
                        variant="inline"
                        required
                        fullWidth
                        label="Event start date"
                        name="eventStartDate"
                        onBlur={handleBlur}
                        value={values.eventStartDate}
                        allowKeyboardControl
                        error={
                          props.initReactivating
                            ? Boolean(errors.eventStartDate)
                            : touched.eventStartDate &&
                              Boolean(errors.eventStartDate)
                        }
                        onChange={(date) => {
                          setFieldValue('eventStartDate', date, true);
                          if (
                            !touched.eventSaleStartDate &&
                            !touched.eventEndDate
                          )
                            setFieldValue(
                              'eventSaleEndDate',
                              getSaleEndDefault(values.eventType, date),
                              true
                            );
                          if (!touched.eventEndDate)
                            setFieldValue('eventEndDate', date, true);
                          if (values.eventType === 'virtual')
                            setFieldValue('resultSubmissionStart', date, true);
                        }}
                        helperText={
                          props.initReactivating && errors.eventStartDate
                            ? errors.eventStartDate
                            : touched.eventStartDate && errors.eventStartDate
                        }
                        autoOk
                        format="PPP"
                        disableMaskedInput
                        inputFormat="dd MMM yyyy"
                        InputProps={{
                          className: classes.input,
                          endAdornment: (
                            <InputAdornment position="start">
                              <CalendarTodayIcon />
                            </InputAdornment>
                          ),
                          initReactivating: props.initReactivating,
                        }}
                        renderInput={(props) => (
                          <TextField
                            fullWidth
                            onKeyDown={onKeyDown}
                            required
                            {...props}
                            readOnly
                            autoComplete="off"
                            name="eventStartDate"
                            error={
                              props.InputProps.initReactivating
                                ? Boolean(errors.eventStartDate)
                                : touched.eventStartDate &&
                                  Boolean(errors.eventStartDate)
                            }
                            helperText={
                              props.InputProps.initReactivating &&
                              errors.eventStartDate
                                ? errors.eventStartDate
                                : touched.eventStartDate &&
                                  errors.eventStartDate
                            }
                          />
                        )}
                      />
                    </span>
                  </Grid>
                  <Grid xs={6}>
                    <span onClick={() => checkReactivationPrompt()}>
                      <DatePicker
                        disablePast
                        variant="inline"
                        required
                        fullWidth
                        disableMaskedInput
                        label="Event end date"
                        name="eventEndDate"
                        onBlur={handleBlur}
                        disabled={
                          !Boolean(values.eventStartDate) || disableDates
                        }
                        value={values.eventEndDate}
                        placeholder="End date of the event"
                        error={
                          props.initReactivating
                            ? Boolean(errors.eventEndDate)
                            : touched.eventEndDate &&
                              Boolean(errors.eventEndDate)
                        }
                        autoOk
                        onChange={(date) => {
                          setFieldValue('eventEndDate', date, true);
                          if (
                            !touched.eventSaleEndDate &&
                            values.eventType === 'virtual'
                          )
                            setFieldValue(
                              'eventSaleEndDate',
                              dayBefore(date),
                              true
                            );
                        }}
                        helperText={
                          props.initReactivating && Boolean(errors.eventEndDate)
                            ? errors.eventEndDate
                            : touched.eventEndDate && errors.eventEndDate
                        }
                        minDate={values.eventStartDate}
                        format="PPP"
                        inputFormat="dd MMM yyyy"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <CalendarTodayIcon />
                            </InputAdornment>
                          ),
                          initReactivating: props.initReactivating,
                        }}
                        renderInput={(props) => (
                          <TextField
                            fullWidth
                            onKeyDown={onKeyDown}
                            required
                            {...props}
                            name="eventEndDate"
                            autoComplete="off"
                            error={
                              props.InputProps.initReactivating
                                ? Boolean(errors.eventEndDate)
                                : touched.eventEndDate &&
                                  Boolean(errors.eventEndDate)
                            }
                            helperText={
                              props.InputProps.initReactivating &&
                              Boolean(errors.eventEndDate)
                                ? errors.eventEndDate
                                : touched.eventEndDate && errors.eventEndDate
                            }
                          />
                        )}
                      />
                    </span>
                  </Grid>
                </Grid>
                <Grid xs={12} style={{ marginTop: 24 }}></Grid>
                <Grid container direction="row" xs={12} spacing={2}>
                  <Grid xs={6}>
                    <span onClick={() => checkReactivationPrompt()}>
                      <DatePicker
                        disabled={disableDates}
                        disablePast
                        disableMaskedInput
                        variant="inline"
                        required
                        fullWidth
                        label="First day of sales"
                        name="eventSaleStartDate"
                        onBlur={handleBlur}
                        value={values.eventSaleStartDate}
                        maxDate={
                          values.eventType === 'virtual'
                            ? values.eventEndDate
                            : dayBefore(values.eventStartDate)
                        }
                        onChange={(date) =>
                          setFieldValue('eventSaleStartDate', date, true)
                        }
                        error={
                          props.initReactivating
                            ? Boolean(errors.eventSaleStartDate)
                            : touched.eventSaleStartDate &&
                              Boolean(errors.eventSaleStartDate)
                        }
                        helperText={
                          props.initReactivating && errors.eventSaleStartDate
                            ? errors.eventSaleStartDate
                            : touched.eventSaleStartDate &&
                              errors.eventSaleStartDate
                        }
                        autoOk
                        format="PPP"
                        inputFormat="dd MMM yyyy"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <CalendarTodayIcon />
                            </InputAdornment>
                          ),
                          initReactivating: props.initReactivating,
                        }}
                        renderInput={(props) => (
                          <TextField
                            fullWidth
                            onKeyDown={onKeyDown}
                            required
                            {...props}
                            name="eventSaleStartDate"
                            autoComplete="off"
                            error={
                              props.InputProps.initReactivating
                                ? Boolean(errors.eventSaleStartDate)
                                : touched.eventSaleStartDate &&
                                  Boolean(errors.eventSaleStartDate)
                            }
                            helperText={
                              props.InputProps.initReactivating &&
                              errors.eventSaleStartDate
                                ? errors.eventSaleStartDate
                                : touched.eventSaleStartDate &&
                                  errors.eventSaleStartDate
                            }
                          />
                        )}
                      />
                    </span>
                  </Grid>
                  <Grid xs={6}>
                    <span onClick={() => checkReactivationPrompt()}>
                      <DatePicker
                        disabled={disableDates}
                        disablePast
                        variant="inline"
                        required
                        fullWidth
                        disableMaskedInput
                        label="Last day of sales"
                        name="eventSaleEndDate"
                        value={values.eventSaleEndDate}
                        placeholder="End date of the event"
                        minDate={values.eventSaleStartDate}
                        maxDate={
                          values.eventType === 'virtual'
                            ? values.eventEndDate
                            : dayBefore(values.eventStartDate)
                        }
                        autoOk
                        onChange={(date) => {
                          setFieldValue('eventSaleEndDate', date, true);
                        }}
                        onBlur={handleBlur}
                        error={
                          props.initReactivating
                            ? Boolean(errors.eventSaleEndDate)
                            : touched.eventSaleEndDate &&
                              Boolean(errors.eventSaleEndDate)
                        }
                        helperText={
                          props.initReactivating &&
                          Boolean(errors.eventSaleEndDate)
                            ? errors.eventSaleEndDate
                            : touched.eventSaleEndDate &&
                              errors.eventSaleEndDate
                        }
                        format="PPP"
                        inputFormat="dd MMM yyyy"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <CalendarTodayIcon />
                            </InputAdornment>
                          ),
                          initReactivating: props.initReactivating,
                        }}
                        renderInput={(props) => (
                          <TextField
                            onKeyDown={onKeyDown}
                            fullWidth
                            required
                            name="eventSaleEndDate"
                            autoComplete="off"
                            {...props}
                            error={
                              props.InputProps.initReactivating
                                ? Boolean(errors.eventSaleEndDate)
                                : touched.eventSaleEndDate &&
                                  Boolean(errors.eventSaleEndDate)
                            }
                            helperText={
                              props.InputProps.initReactivating &&
                              Boolean(errors.eventSaleEndDate)
                                ? errors.eventSaleEndDate
                                : touched.eventSaleEndDate &&
                                  errors.eventSaleEndDate
                            }
                          />
                        )}
                      />
                    </span>
                  </Grid>
                </Grid>
                {values.eventType === 'virtual' && (
                  <Grid container direction="row" xs={12} spacing={2}>
                    <Grid xs={6}>
                      <span onClick={() => checkReactivationPrompt()}>
                        <DatePicker
                          disabled={disableDates}
                          disablePast
                          variant="inline"
                          disableMaskedInput
                          required
                          fullWidth
                          minDate={values.eventStartDate}
                          label="Result submission start"
                          name="resultSubmissionStart"
                          value={values.resultSubmissionStart}
                          onChange={(date) =>
                            setFieldValue('resultSubmissionStart', date, true)
                          }
                          autoOk
                          format="PPP"
                          inputFormat="dd MMM yyyy"
                          error={
                            touched.resultSubmissionStart &&
                            Boolean(errors.resultSubmission)
                          }
                          helperText={
                            touched.resultSubmissionStart &&
                            errors.resultSubmission
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <CalendarTodayIcon />
                              </InputAdornment>
                            ),
                          }}
                          renderInput={(props) => (
                            <TextField
                              onKeyDown={onKeyDown}
                              fullWidth
                              required
                              name="resultSubmissionStart"
                              {...props}
                              error={
                                touched.resultSubmissionStart &&
                                Boolean(errors.resultSubmission)
                              }
                              helperText={
                                touched.resultSubmissionStart &&
                                errors.resultSubmission
                              }
                            />
                          )}
                        />
                      </span>
                    </Grid>
                    <Grid xs={6}>
                      <span onClick={() => checkReactivationPrompt()}>
                        <DatePicker
                          disabled={disableDates}
                          disablePast
                          variant="inline"
                          required
                          fullWidth
                          disableMaskedInput
                          label="Result submission end"
                          name="resultSubmissionEnd"
                          value={values.resultSubmissionEnd}
                          minDate={values.eventStartDate}
                          onChange={(date) =>
                            setFieldValue('resultSubmissionEnd', date, true)
                          }
                          autoOk
                          format="PPP"
                          inputFormat="dd MMM yyyy"
                          error={
                            touched.resultSubmissionStart &&
                            Boolean(errors.resultSubmission)
                          }
                          helperText={
                            touched.resultSubmissionStart &&
                            errors.resultSubmission
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <CalendarTodayIcon />
                              </InputAdornment>
                            ),
                          }}
                          renderInput={(props) => (
                            <TextField
                              fullWidth
                              onKeyDown={onKeyDown}
                              required
                              name="resultSubmissionEnd"
                              {...props}
                              error={
                                touched.resultSubmissionStart &&
                                Boolean(errors.resultSubmission)
                              }
                              helperText={
                                touched.resultSubmissionStart &&
                                errors.resultSubmission
                              }
                            />
                          )}
                        />
                      </span>
                    </Grid>
                  </Grid>
                )}

                <Divider className={classes.divider} />

                <Grid xs={12}>
                  <Typography gutterBottom>
                    Images that showcase your event*
                  </Typography>
                  <ImageUpload
                    maxFiles={maximumNumberOfImages}
                    uploadUrl={
                      props.isUpdate || props.initReactivating
                        ? uploadImageUrl(event?.eventId)
                        : null
                    }
                    onChange={(newFiles) =>
                      setFieldValue(
                        'eventImages',
                        values.eventImages.concat(
                          newFiles.slice(
                            0,
                            maximumNumberOfImages - values.eventImages.length
                          )
                        )
                      )
                    }
                    onReorder={(files) => setFieldValue('eventImages', files)}
                    files={values.eventImages}
                    name="eventImages"
                    helperText={
                      imageHelperText + values.eventImages.count ==
                      maximumNumberOfImages
                        ? maxImagesReached
                        : null
                    }
                    errorMessage={
                      touched.eventImages &&
                      Boolean(errors.eventImages) &&
                      (errors.eventImages ||
                        (imageHelperText + values.eventImages.count ==
                        maximumNumberOfImages
                          ? maxImagesReached
                          : null))
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <Typography gutterBottom>Event promo video</Typography>
                  <FormikTextField
                    variant="outlined"
                    fullWidth
                    label="YouTube video link"
                    name="eventVideo"
                    value={values.eventVideo}
                    placeholder="YouTube video link"
                  />
                  <FormHelperText
                    className={classes.helperText}
                    color="initial"
                  >
                    The video will be displayed on your event page together with
                    the event images.
                  </FormHelperText>
                </Grid>

                <Divider className={classes.divider} />

                <Grid xs={12}>
                  <Typography gutterBottom>Event description*</Typography>
                  <div
                    className={classes.description}
                    style={{
                      borderColor:
                        touched.eventDescription &&
                        Boolean(errors.eventDescription)
                          ? 'red'
                          : null,
                    }}
                  >
                    <Editor
                      toolbar={{
                        options: ['inline', 'list'],
                        inline: { options: ['bold', 'italic'] },
                        list: { options: ['unordered'] },
                      }}
                      defaultEditorState={editorState}
                      label="eventDescription"
                      onChange={(e) => {
                        setTouched({ ...touched, eventDescription: true });
                        setFieldValue('eventDescription', e, true);
                        setFieldValue(
                          'eventDescriptionHtml',
                          stateToHTML(convertFromRaw(e))
                        );
                        setLastUpdateToEditor(new Date());
                      }}
                    />
                  </div>
                  <FormHelperText
                    className={classes.helperText}
                    error={
                      touched.eventDescription &&
                      Boolean(errors.eventDescription)
                    }
                  >
                    {touched.eventDescription &&
                    Boolean(errors.eventDescription)
                      ? errors.eventDescription
                      : `Write a description that attracts your potential participants. 
                    Inject your personality into it and let readers feel how passionate you are about your event!`}
                  </FormHelperText>
                </Grid>

                <Divider className={classes.divider} />

                <Grid xs={12}>
                  <CurrencyPicker
                    onChange={handleChange}
                    eventCurrency={values.eventCurrency}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    disabled={props.isUpdate && !canChangeCurrency}
                    isUpdate={props.isUpdate}
                  />
                </Grid>
                {props.isManage && (
                  <Grid
                    container
                    className={classes.buttonsGrid}
                    justifyContent="flex-end"
                    spacing={0}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        await handleSubmit();
                      }}
                      disabled={
                        !dirty ||
                        isSubmitting ||
                        (now.getTime() - lastUpdateToEditor.getTime() < 2000 &&
                          lastUpdateToEditor > new Date(0))
                      }
                    >
                      {isSubmitting
                        ? 'Saving basic information…'
                        : 'Save basic information'}
                    </Button>
                  </Grid>
                )}
              </Grid>
              {eventDatesModal && (
                <EventDatesModal
                  open={eventDatesModal}
                  tickets={ticketModal}
                  eventId={event.eventId}
                  editionId={event.editionId}
                  handleClose={() => toggleEventDatesModal(false)}
                  startDate={values.eventStartDate}
                  endDate={values.eventEndDate}
                  updateTickets={updateTickets}
                />
              )}
              {priceStepsModal && (
                <PriceStepsModal
                  open={priceStepsModal}
                  tickets={priceStepsTickets}
                  handleClose={() => togglePriceStepsModal(false)}
                  eventId={event.eventId}
                  editionId={event.editionId}
                />
              )}

              <FocusFormikError />
            </Grid>
          )}
        </BasicInfoValidation>
      </StepWrapper>
      {reactivationPrompt && (
        <ConfirmationModal
          open={reactivationPrompt}
          cancel={() => setReactivationPrompt(false)}
          title="You cannot change dates"
          content="After the event takes place, the dates cannot be changed. 
        If you want to set up a new edition for this event, please activate next edition."
          confirmText="Activate next edition"
          cancelText="Cancel"
          confirm={() => {
            setReactivationPrompt(false);
            if (futureEvent)
              navigate(
                `/app/reactivate-event/${event.eventId}/${futureEvent.id}`
              );
            else toggleReactivateInfoModal(true);
          }}
        />
      )}
      {reactivateInfoModal && (
        <ReactivationModal
          open={reactivateInfoModal}
          handleClose={() => toggleReactivateInfoModal(false)}
          eventId={event.eventId}
          eventTitle={basicInfo.eventTitle}
        />
      )}
      {cantChangeDateModal && (
        <GenericModal
          open={cantChangeDateModal}
          handleClose={() => toggleCantChangeDateModal(false)}
          title="You cannot change dates"
          content="After the event takes place, the dates cannot be changed."
        />
      )}
    </>
  );
};

export default BasicInfo;
