import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertFromRaw,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { useField, useFormikContext } from 'formik';

const useStyles = makeStyles(() => ({
  answer: {
    border: `1px solid rgba(53, 62, 68, 0.25)`,
    borderRadius: 3,
    '&:hover': {
      border: `1px solid rgba(53, 62, 68, 0.75)`,
    },
    '& div[role="textbox"]': {
      width: '100%',
      paddingLeft: 16.5,
      paddingRight: 16.5,
    },
  },
  error: {
    padding: '16px',
    marginBlockStart: '8px',
    fontSize: '12px',
    fontWeight: 400,
    color: `red`,
    '&:empty': {
      display: `none !important`,
      margin: 0,
      padding: 0,
    },
  },
}));

const CustomAnswer = ({ i, editHandler }) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(`customs[${i}].answer`);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(field.value ?? ''))
    )
  );

  return (
    <>
      <div
        className={classes.answer}
        style={{ borderColor: meta.error ? 'red' : null }}
      >
        <Editor
          toolbar={{
            options: ['inline', 'list'],
            inline: { options: ['bold', 'italic'] },
            list: { options: ['unordered'] },
          }}
          name={`customs[${i}].answer`}
          defaultEditorState={editorState}
          onContentStateChange={(e) => {
            setEditorState(EditorState.createWithContent(convertFromRaw(e)));
            setFieldValue(
              `customs[${i}].answer`,
              stateToHTML(editorState.getCurrentContent())
            );
            editHandler(stateToHTML(editorState.getCurrentContent()));
          }}
        />
      </div>
      <p className={classes.error}>{meta.error ?? ''}</p>
    </>
  );
};

export default CustomAnswer;
